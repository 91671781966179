<script>
import EzEmptyPage from '@dmant/ez-ui-components/src/components/empty-page';
import funnelsManager from '@/services/funnelsManager';

export default {
  name: 'EzfShareDialog',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    EzEmptyPage,
  },
  methods: {
    close() {
      this.$modal.hide(this.name);
    },
  },
  async created() {
    const hash = this.$route.params.share_hash;
    try {
      const result = await funnelsManager.shareImport(hash);
      this.close();
      this.$router.replace({ name: 'funnelBuilder', params: { funnel_id: result.id } });
    } catch (e) {
      const unsubscribe = this.$watch(
        () => !this.$store.state.funnels.funnelsLoading && this.$store.state.funnels.funnels,
        (funnels) => {
          if (!funnels) {
            return;
          }

          this.$nextTick(() => unsubscribe());
          this.close();

          for (const { shareHash, id, clonedFrom } of funnels) {
            if (shareHash === hash || (clonedFrom && clonedFrom.shareHash === hash)) {
              this.$router.replace({ name: 'funnelBuilder', params: { funnel_id: id } });
              return;
            }
          }

          this.$notify({
            data: {
              type: 'error',
              content: e.message || e,
            },
          });
        },
        { immediate: true },
      );
    }
  },
};
</script>

<template>
  <ez-form ref="form">
    <ez-dialog-header icon="download">
      {{ $t("funnel.shareImport.dialogTitle") }}
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <ez-empty-page>
        <template slot="image">
          <div
            style="position: relative; margin-top: 50px; margin-left: -25px;"
          >
            <ez-preloader :value="true" :stop="false" />
          </div>
          <div style="height: 30px; overflow: hidden"></div>
        </template>

        <template slot="title">
          Share is in progress.<br />
          Please wait.
        </template>
      </ez-empty-page>
    </ez-dialog-body>
  </ez-form>
</template>

<style>
.empty-page__title {
  text-align: center;
}
</style>
