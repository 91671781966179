<script>
import EzfShareFunnelDialog from './dialog.vue';

export default {
  name: 'EzfFunnelShareImport',
  components: {
    EzfFunnelList: () => import(/* webpackChunkName: "funnelsOverview" */ '@/pages/funnels/list.vue'),
  },
  created() {
    const props = {
      name: 'share',
    };
    this.$modal.show(EzfShareFunnelDialog, props, {
      name: props.name,
      height: 'auto',
      width: 600 /* "is-xmedium" */,
      // draggable: true,
      scrollable: true,
      classes: ['dialog', 'is-service-funnels'],
    });
  },
};
</script>

<template>
  <EzfFunnelList />
</template>

<style></style>
